.container {
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--surface-neutral-default);
  cursor: pointer;
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
}

.key-icon {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(190deg, #c1e8fd 21.02%, #ffe1ff 111.76%);
}
