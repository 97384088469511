.container {
  margin: 64px 24px 0;
  overflow: hidden;
  border-radius: 8px;
}

.banner {
  all: unset;
  cursor: pointer;
}

.content {
  min-width: 700px;
  position: relative;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  height: 200px;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  border-radius: 8px;
  gap: 16px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  all: unset;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: var(--text-primary-default);
}

.description {
  all: unset;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  color: var(--text-primary-default);
}

.label {
  width: 190px;
  height: 32px;
  flex-shrink: 0;
  background: url('./assets/label.png') 50% / cover no-repeat;
}

.house-1 {
  position: absolute;
  right: 133px;
  bottom: 0;
  width: 117px;
  height: 135px;
  background: url('./assets/house1.svg');
}

.house-2 {
  position: absolute;
  right: 52px;
  bottom: 0;
  width: 116px;
  height: 190px;
  background: url('./assets/house2.svg');
}

.coin {
  position: absolute;
  right: 8px;
  bottom: 0;
  width: 90px;
  height: 71px;
  background: url('./assets/coin.svg');
}
