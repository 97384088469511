.container {
  margin-top: -24px;
  margin-bottom: 24px;
  padding: 0 24px;
  width: 100%;
}

.wrapper {
  padding: 16px 16px 16px 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: start;
  border-radius: 8px;
  background-color: var(--surface-neutral-default);
}

.title p:last-child {
  margin-top: 4px;
  cursor: pointer;
}

.icon {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
